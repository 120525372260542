<template>
  <v-container fluid fill-height style='height: 90vh'>
    <v-row no-gutters align='center' justify='center'>
      <v-card
        flat
        width='450'
      >
        <v-container fluid justify='center' align='center' class='h-100'>
          <v-card-title>{{$t('NEW_USER')}}</v-card-title>
          <v-card-subtitle>{{$t('PROFILE_DATA')}}</v-card-subtitle>

          <v-row no-gutters>
            <v-card-text class='pt-0'>
              <v-form v-model='formIsValid' lazy-validation>
                <v-text-field
                  v-model='user.name'
                  :rules='[rules.required]'
                  :label='$t("NAME")'
                  clearable
                />
                <v-text-field
                  v-model='user.email'
                  :rules='[rules.required]'
                  :label='$t("EMAIL")'
                  :hint='$t("EMAIL_TIP_NEW_USER")'
                  clearable
                />
                <v-text-field
                  v-model='user.cpf'
                  :rules='[rules.required]'
                  :label='$t("CPF")'
                  :hint='$t("CPF_DIGITS_ONLY")'
                  clearable
                />
              </v-form>

              <v-select
                v-if='isSuperAdmin'
                v-model='user.role'
                :label='$t("ROLE")'
                :items='roleList'
                item-value='id'
                item-text='description'
                clearable
              />

              <v-autocomplete
                v-model='customerSelect'
                :items='customerList'
                item-text='name'
                return-object
                label='Cliente'
                placeholder='Selecione um cliente'
              />

              <v-btn block color='secondary' class='mt-8' @click='save'>
                {{$t('SAVE')}}
              </v-btn>
            </v-card-text>
          </v-row>
        </v-container>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  import { ACCOUNT_ROLE } from '@/store/modules/Auth';

  export default {
    name: 'UsersDetails',
    data: function () {
      return {
        formIsValid: false,
        showPassword: false,
        rules: {
          required: (value) => !!value || this.$t('REQUIRED'),
          passwordMatch: (value) => (value === this.user.password) || this.$t('PASSWORDS_ENTERED_DO_NOT_MATCH'),
        },
        roleList: [],
        user: {
          name: undefined,
          email: undefined,
          cpf: undefined,
          role: undefined,
        },
        customerSelect: '',
        customerList: [],
      };
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
      isSuperAdmin: function () {
        return this.getUser.account.accountRoleId === ACCOUNT_ROLE.SUPER_ADMIN;
      },
    },
    mounted: function () {
      this.getRoleList();
      this.getCustomers();
    },
    methods: {
      togglePasswordVisibility: function () {
        this.showPassword = !this.showPassword;
      },
      getRoleList: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading', true);
          const { data } = await axios({
            url: '/account-role-list',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          let list = data && data.list;
          if (list) {
            list = list.map((item) => ({
              id: item.id,
              description: this.$t(item.code),
            }));
          }
          this.roleList = list;
          this.$store.dispatch('loading/toggleLoading', false);
        } catch (e) {
          this.$store.dispatch('loading/toggleLoading', false);
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      save: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading', true);
          await axios({
            url: '/account-new',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            withCredentials: true,
            data: {
              name: this.user.name,
              email: this.user.email,
              cpf: this.user.cpf,
              roleId: this.user.role,
              customerId: this.customerSelect.id,
            },
          });

          this.$store.dispatch('loading/toggleLoading', false);
          this.$emit('new-user');
          this.$emit('close');
        } catch (e) {
          this.$store.dispatch('loading/toggleLoading', false);
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      getCustomers: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading', true);
          const result = await axios({
            url: '/customers',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.customerList = result.data.customers;
          this.$store.dispatch('loading/toggleLoading', false);
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading', false);
        }
      },
    },
  };
</script>
